jQuery(function ($) {
  /* global wc_add_to_cart_params */
  if (typeof wc_add_to_cart_params === 'undefined') {
    return false;
  }

  $(document).on('submit', 'form.cart', function (e) {
    var form = $(this),
      button = form.find('.single_add_to_cart_button');

    var formFields = form.find(
      'input:not([name="product_id"]), select, button, textarea'
    );

    // create the form data array
    var formData = [];
    formFields.each(function (i, field) {
      // store them so you don't override the actual field's data
      var fieldName = field.name,
        fieldValue = field.value;

      if (fieldName && fieldValue) {
        // set the correct product/variation id for single or variable products
        if (fieldName == 'add-to-cart') {
          fieldName = 'product_id';
          fieldValue =
            form.find('input[name=variation_id]').val() || fieldValue;
        }

        // if the fiels is a checkbox/radio and is not checked, skip it
        if (
          (field.type == 'checkbox' || field.type == 'radio') &&
          field.checked == false
        ) {
          return;
        }

        // add the data to the array
        formData.push({
          name: fieldName,
          value: fieldValue,
        });
      }
    });

    if (!formData.length) {
      return;
    }

    e.preventDefault();

    form.block({
      message: null,
      overlayCSS: {
        background: '#ffffff',
        opacity: 0,
      },
    });

    $(document.body).trigger('adding_to_cart', [button, formData]);

    $.ajax({
      type: 'POST',
      // eslint-disable-next-line no-undef
      url: woocommerce_params.wc_ajax_url
        .toString()
        .replace('%%endpoint%%', 'add_to_cart'),
      data: formData,
      success: function (response) {
        if (!response) {
          return;
        }

        if (response.error & response.product_url) {
          window.location = response.product_url;
          return;
        }

        $(document.body).trigger('added_to_cart', [
          response.fragments,
          response.cart_hash,
          button,
        ]);
      },
      complete: function () {
        form.unblock();
      },
    });

    return false;
  });

  /**
   * Update Cart on Quantity Change
   */
  let timeout;
  $('.woocommerce').on('change', 'input.qty', function () {
    var $this = $(this);
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function () {
      $this.closest('form').find("[name='update_cart']").trigger('click'); // trigger cart update
    }, 500); // 1 second delay, half a second (500) seems comfortable too
  });

  /**
   * Update Coupon Code on Change
   */
  let couponTimeout;
  $('.woocommerce').on('change', "[name='coupon_code']", function () {
    var $this = $(this);
    if (couponTimeout !== undefined) {
      clearTimeout(couponTimeout);
    }
    couponTimeout = setTimeout(function () {
      $this.closest('form').find("[name='apply_coupon']").trigger('click'); // trigger cart update
    }, 500); // 1 second delay, half a second (500) seems comfortable too
  });
  // Trigger Mini Cart Slide In on Add to Cart
  // eslint-disable-next-line no-unused-vars
  $(document.body).on('added_to_cart', function (event) {
    $('.mini-cart').addClass('is-open');

    setTimeout(function () {
      $('.mini-cart').removeClass('is-open');
    }, 5000);
  });

  jQuery(document).on('submit', '.mini-cart form', function () {
    updateMiniCartQuantity();
    return false;
  });

  function updateMiniCartQuantity() {
    var cartForm = jQuery('.mini-cart form');
    jQuery('<input />')
      .attr('type', 'hidden')
      .attr('name', 'update_cart')
      .attr('value', 'Update Cart')
      .appendTo(cartForm);

    cartForm.block({
      message: null,
      overlayCSS: {
        background: '#fff',
        opacity: 0.5,
      },
    });

    var formData = cartForm.serialize();
    jQuery.ajax({
      type: cartForm.attr('method'),
      url: cartForm.attr('action'),
      data: formData,
      dataType: 'html',
      // eslint-disable-next-line no-unused-vars
      success: function (response) {
        // eslint-disable-next-line no-undef
        let wc_cart_fragment_url = wc_cart_fragments_params.wc_ajax_url.replace(
          '%%endpoint%%',
          'get_refreshed_fragments'
        );
        jQuery.ajax({
          type: 'post',
          url: wc_cart_fragment_url,
          success: function (response) {
            var mini_cart_wrapper = jQuery('div.mini-cart__wrapper');
            var parent = mini_cart_wrapper.parent();
            mini_cart_wrapper.remove();
            parent.append(response.fragments['div.mini-cart__wrapper']);
          },
          complete: function () {
            cartForm = jQuery('.mini-cart form');
            cartForm.unblock();
          },
        });
      },
    });
  }

  /**
   * Disable Checkout Button if there are errors
   */
  checkCheckoutbuttonState();

  jQuery('body.woocommerce-cart').on(
    'click',
    '.wc-proceed-to-checkout',
    function (event) {
      if (jQuery('.woocommerce-error').length > 0) {
        checkCheckoutbuttonState();
        event.preventDefault();
      }
    }
  );

  function checkCheckoutbuttonState() {
    const checkoutButton = document.querySelector(
      '.woocommerce-cart .checkout-button'
    );
    if (checkoutButton) {
      if (jQuery('.woocommerce-error').length > 0) {
        checkoutButton.classList.add('disabled');
        $('html,body').scrollTop(0);
      } else {
        checkoutButton.classList.remove('disabled');
      }
    }
  }

  $(document.body).on('updated_cart_totals', function () {
    checkCheckoutbuttonState();
  });
});
