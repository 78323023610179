import domReady from '@roots/sage/client/dom-ready';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);

/**
 * Application entrypoint
 */
domReady(async () => {
  window.addEventListener('load', () => {
    document.body.classList.add('loaded');
  });

  const hamburger = document.querySelector('.banner .hamburger');
  const navigation = document.querySelector('.banner .nav-primary');
  const menuItems = document.querySelectorAll('.banner .menu-item');

  if (hamburger && navigation) {
    hamburger.addEventListener('click', toggleNavVisibility);
  }

  if (menuItems) {
    menuItems.forEach((item) => {
      item.addEventListener('click', toggleNavVisibility);
    });
  }

  function toggleNavVisibility() {
    let navState = navigation.getAttribute('status');

    if (!hamburger.classList.contains('filter-visible')) {
      if (navState === 'visible') {
        navigation.setAttribute('status', 'hidden');
        hamburger.setAttribute('aria-expanded', 'false');
        document.body.classList.remove('nav-open');
      } else {
        navigation.setAttribute('status', 'visible');
        hamburger.setAttribute('aria-expanded', 'true');
        document.body.classList.add('nav-open');
      }
    } else {
      filterContainer.classList.remove('is-visible');
      hamburger.classList.remove('filter-visible');
    }
  }

  /**
   * Mobile Filter Toggle
   */
  const filterToggle = document.querySelector('.category-filter-button');
  const filterContainer = document.querySelector('.category-filters');

  if (filterToggle && filterContainer) {
    filterToggle.addEventListener('click', toggleFilterVisibility);
  }

  function toggleFilterVisibility() {
    if (filterContainer.classList.contains('is-visible')) {
      filterContainer.classList.remove('is-visible');
      hamburger.classList.remove('filter-visible');
    } else {
      filterContainer.classList.add('is-visible');
      hamburger.classList.add('filter-visible');
    }
  }

  /**
   * 100vh iOS Workaround
   */
  const setVh = (proportyName) => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(proportyName, `${vh}px`);
  };

  window.addEventListener('load', function () {
    setVh('--vh');
  });

  window.addEventListener('resize', function () {
    setVh('--vh');
  });

  /**
   * Header Height
   */
  let bannerHeight = document.querySelector('.banner__inner').clientHeight;

  let setHeaderHeight = (proportyName) => {
    if (document.querySelector('.banner__inner')) {
      bannerHeight = document.querySelector('.banner__inner').clientHeight;

      document.documentElement.style.setProperty(
        proportyName,
        `${bannerHeight}px`
      );
    }
  };

  window.addEventListener('load', function () {
    setHeaderHeight('--header-height');
  });

  window.addEventListener('resize', function () {
    setHeaderHeight('--header-height');
  });

  const banner = document.querySelector('.banner');

  gsap.set(banner, {
    y: 0,
  });

  function bannerAnimation() {
    gsap.matchMedia().add('(min-width: 1024px)', () => {
      let bannerHeight =
        -document.querySelector('.banner__inner').clientHeight - 2;
      let shopTimeline = gsap.timeline();

      if (
        document.body.classList.contains('woocommerce-shop') &&
        document.querySelector('.woocommerce-product-search')
      ) {
        bannerHeight -=
          document.querySelector('.nav-primary__inner').clientHeight - 8;

        shopTimeline = gsap.fromTo(
          banner,
          {
            y: () => bannerHeight,
          },
          {
            y: () => -document.querySelector('.banner__inner').clientHeight - 2,
            duration: 0.5,
            ease: 'power1.inOut',
            paused: true,
          }
        );
      }

      let bannerTimeline = gsap.fromTo(
        banner,
        {
          y: 0,
        },
        {
          y: () => bannerHeight,
          duration: 0.5,
          ease: 'power1.inOut',
          paused: true,
        }
      );

      ScrollTrigger.create({
        trigger: '#app',
        start: '5 top',
        end: 'bottom top',
        onLeaveBack: () => {
          if (
            document.body.classList.contains('woocommerce-shop') &&
            document.querySelector('.woocommerce-product-search')
          ) {
            gsap.to(banner, {
              y: 0,
              duration: 0.5,
              ease: 'power1.inOut',
            });
          } else {
            bannerTimeline.reverse();
          }
        },
        onEnter: () => {
          bannerTimeline.play(0);
        },
        onUpdate: (self) => {
          if (
            !document.body.classList.contains('woocommerce-shop') ||
            !document.querySelector('.woocommerce-product-search')
          ) {
            return;
          }

          if (!bannerTimeline.isActive() && self.direction === -1) {
            shopTimeline.play();
          } else if (!shopTimeline.isActive() && self.direction === 1) {
            shopTimeline.reverse();
          }
        },
      });
    });
  }

  bannerAnimation();

  /**
   * Product Filter
   */
  const productFilter = gsap.utils.toArray(
    '.woocommerce-shop .category-filters__button'
  );

  const products = gsap.utils.toArray('.woocommerce-shop .product');

  function updateProjectFilter() {
    const classes = productFilter
      .filter((button) => button.classList.contains('is-active'))
      .map((button) => '.product_cat-' + button.id);
    const matches = classes.length
      ? gsap.utils.toArray(classes.join(', '))
      : classes;

    products.forEach((product) => {
      if (
        matches.indexOf(product) === -1 &&
        document.querySelector(
          '.woocommerce-shop .category-filters__button.is-active'
        )
      ) {
        product.style.opacity = '0';
        product.classList.add('is-invisible');
      } else {
        product.style.opacity = '1';
        product.classList.remove('is-invisible');
      }
    });
  }

  /**
   * Scroll to active product if it's out of view
   */
  function scrollToProduct(
    firstActiveProduct = document.querySelector(
      '.woocommerce-shop .products .product:not(.is-invisible)'
    )
  ) {
    if (firstActiveProduct) {
      let productPos = firstActiveProduct.offsetTop;
      let scrollPos = window.scrollY - bannerHeight;

      if (
        productPos >= scrollPos + window.innerHeight ||
        productPos <= scrollPos
      ) {
        firstActiveProduct.scrollIntoView({ behaviour: 'smooth' });
      }
    }
  }

  /**
   * Select Product Filter via Url
   */
  if (productFilter) {
    productFilter.forEach(function (categoryButton) {
      categoryButton.addEventListener('click', function () {
        this.classList.toggle('is-active');

        updateProjectFilter();

        let activeProductClass;

        if (this.classList.contains('is-active')) {
          activeProductClass = '.product.product_cat-' + this.id;
        } else {
          activeProductClass =
            '.woocommerce-shop .products .product:not(.is-invisible)';
        }

        scrollToProduct(document.querySelector(activeProductClass));
      });
    });
  }

  window.addEventListener('load', () => {
    if (window.location.hash) {
      const activeButton = document.querySelector(window.location.hash);

      if (activeButton && !activeButton.classList.contains('is-active')) {
        activeButton.classList.add('is-active');
        updateProjectFilter();
        scrollToProduct();
        history.pushState(
          '',
          document.title,
          window.location.pathname + window.location.search
        );
      }
    }
  });

  /**
   * Product Position
   */
  const productList = document.querySelectorAll(
    '.woocommerce-shop .products .product a.woocommerce-loop-product__link, .wp-block-featured-products .product a.woocommerce-loop-product__link'
  );

  if (productList) {
    productList.forEach((product) => {
      product.addEventListener('click', (event) => {
        event.preventDefault();
        let arrayPos = Array.prototype.indexOf.call(productList, product) + 1;

        if (arrayPos && (arrayPos + 3) % 4 == 0) {
          window.sessionStorage.setItem('product-position', 1);
        } else if (arrayPos && (arrayPos + 2) % 4 == 0) {
          window.sessionStorage.setItem('product-position', 2);
        } else if (arrayPos && (arrayPos + 1) % 4 == 0) {
          window.sessionStorage.setItem('product-position', 3);
        } else if (arrayPos && arrayPos % 4 == 0) {
          window.sessionStorage.setItem('product-position', 4);
        }
        window.open(product.href, '_self');
      });
    });
  }

  let singleProduct = document.querySelector('.single-product .product');

  if (singleProduct && window.sessionStorage.getItem('product-position')) {
    let className =
      'is-product-position--' +
      window.sessionStorage.getItem('product-position');
    singleProduct.classList.add('has-product-position', className);
  }

  /**
   * Accordion Toggle
   */

  function toggleItems(
    item,
    accordionContent,
    headline,
    defaultText,
    closeText
  ) {
    if (accordionContent.style.maxHeight) {
      accordionContent.style.maxHeight = null;
      item.classList.toggle('is-open');
      if (closeText) {
        headline.textContent = defaultText;
      }
    } else {
      item.classList.toggle('is-open');
      accordionContent.style.maxHeight =
        accordionContent.scrollHeight + 40 + 'px';
      if (closeText) {
        headline.textContent = closeText;
      }
    }
  }

  function enableAccordion(accordionItemClass, headlineClass, contentClass) {
    const accordionItems = document.querySelectorAll(accordionItemClass);

    if (accordionItems) {
      accordionItems.forEach((accordionItem) => {
        let headline = accordionItem.querySelector(headlineClass);
        let content = accordionItem.querySelector(contentClass);
        let defaultText = headline.textContent;
        let closeText = false;

        if (accordionItem.classList.contains('is-open')) {
          content.style.maxHeight = content.scrollHeight + 'px';
        }

        if (headline.dataset.close && headline.textContent) {
          closeText = headline.dataset.close;
        }

        headline.addEventListener('click', function () {
          toggleItems(accordionItem, content, headline, defaultText, closeText);
        });
      });
    }
  }

  enableAccordion(
    '.wp-block-accordion__item',
    '.wp-block-accordion__headline',
    '.wp-block-accordion__content'
  );

  const firstNewsItem = document.querySelector('.blog .post:first-child');

  if (firstNewsItem) {
    firstNewsItem.classList.add('is-open');
  }

  enableAccordion('.blog .post', '.blog .post__header', '.blog .post__content');

  /**
   * Woocommerce Quantity Select Buttons
   */
  document.addEventListener('click', function (e) {
    // Get current quantity values
    if (!e.target.matches('.quantity__button')) return;

    let qty = e.target.closest('.quantity').querySelector('.qty');
    let val = qty.value == '' ? 0 : parseFloat(qty.value);
    let max = parseFloat(qty.getAttribute('max'));
    let min = parseFloat(qty.getAttribute('min'));
    let step = parseFloat(qty.getAttribute('step'));
    let addToCartButton =
      e.target.closest('.cart')?.querySelector('.add_to_cart_button') || qty;
    let btnupdate = document.querySelector(
      '.woocommerce-cart button[name="update_cart"]'
    );

    // Change the value if plus or minus
    if (e.target.classList.contains('quantity__plus')) {
      if (max && max <= val) {
        qty.value = max;
      } else {
        qty.value = val + step;
        qty.dispatchEvent(new Event('change', { bubbles: true }));
        if (btnupdate) {
          btnupdate.removeAttribute('disabled');
          btnupdate.removeAttribute('aria-disabled');
        }
      }
    } else {
      if (min && min >= val) {
        qty.value = min;
      } else if (val >= 1) {
        qty.value = val - step;
        qty.dispatchEvent(new Event('change', { bubbles: true }));
        if (btnupdate) {
          btnupdate.removeAttribute('disabled');
          btnupdate.removeAttribute('aria-disabled');
        }
      }
    }

    addToCartButton.dataset.quantity = qty.value;
  });

  const addToCartButton = document.querySelector(
    '.single_add_to_cart_button, .add_to_cart_button'
  );

  if (addToCartButton) {
    addToCartButton.addEventListener('click', () => {
      addToCartButton.classList.add('added-visible');

      setTimeout(() => {
        addToCartButton.classList.remove('added-visible');
      }, '5000');
    });
  }

  /**
   * Change Home Header Title depending on current section
   */
  function changeHomeTitle(title) {
    if (document.body.classList.contains('home')) {
      const titleContainer = document.querySelector('.mobile-menu__page-title');

      titleContainer.innerHTML = title;
    }
  }

  if (document.body.classList.contains('home')) {
    const anchorMenuItems = document.querySelectorAll(
      ".banner .menu-item a[href^='#']"
    );

    anchorMenuItems.forEach((item, index) => {
      const section = document.querySelector(item.hash);
      const title = index + 1 + ' – ' + item.dataset.title;

      if (index === 0) {
        changeHomeTitle(title);
      }

      ScrollTrigger.create({
        trigger: section,
        start: '50 top',
        end: 'bottom bottom',
        onEnter: () => {
          changeHomeTitle(title);
        },
        onEnterBack: () => {
          changeHomeTitle(title);
        },
      });
    });
  }

  const miniCart = document.querySelector('.mini-cart');

  if (miniCart) {
    miniCart.addEventListener('mouseenter', () => {
      miniCart.classList.add('is-hovered');
    });

    miniCart.addEventListener('mouseleave', () => {
      miniCart.classList.remove('is-hovered');
    });
  }

  /**
   * Popup Modal with Timeout and Sessionstorage
   */
  const popup = document.querySelector('.popup__wrapper');
  const showPopup = (elem) => {
    elem.classList.add('is-open');
    window.sessionStorage.setItem(elem.id, true);
  };

  const hidePopup = (elem) => {
    elem.classList.remove('is-open');
  };

  if (popup) {
    if (!window.sessionStorage.getItem(popup.id)) {
      setTimeout(function () {
        showPopup(popup);
      }, 2000);
    }

    const exits = popup.querySelectorAll('.popup-close');
    exits.forEach(function (exit) {
      exit.addEventListener('click', function (event) {
        event.preventDefault();
        hidePopup(popup);
      });
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        hidePopup(popup);
      }
    });
  }

  /**
   * Set the height of Downloads Container
   */
  function setDownloadsHeight(groupClass, containerClass) {
    const downloads = document.querySelectorAll(groupClass);
    if (downloads) {
      let downloadHeights = [];

      downloads.forEach((download) => {
        downloadHeights.push(download.clientHeight);
      });

      let downloadHeight = Math.max(...downloadHeights);

      if (downloadHeight && document.querySelector(containerClass)) {
        document.querySelector(containerClass).style.height =
          downloadHeight + 'px';
      }
    }
  }

  window.addEventListener('load', function () {
    setDownloadsHeight(
      '.downloads .downloads__group',
      '.post-type-archive-sbr_download .downloads'
    );
    setDownloadsHeight(
      '.download__press--tabs .download__press__group',
      '.download__press--tabs .download__press__groups'
    );
  });
  window.addEventListener('resize', function () {
    setDownloadsHeight(
      '.downloads .downloads__group',
      '.post-type-archive-sbr_download .downloads'
    );
    setDownloadsHeight(
      '.download__press--tabs .download__press__group',
      '.download__press--tabs .download__press__groups'
    );
  });

  /**
   * Toggle Downloads
   */
  function toggleDownloads(button, buttonClass, groupClass) {
    let activeDownloadId = button.dataset.group;

    if (document.querySelector(buttonClass + '.is-active')) {
      document
        .querySelector(buttonClass + '.is-active')
        .classList.remove('is-active');
    }

    if (document.querySelector(groupClass + '.is-active')) {
      document
        .querySelector(groupClass + '.is-active')
        .classList.remove('is-active');
    }

    button.classList.add('is-active');

    if (activeDownloadId) {
      let activeDownload = document.getElementById(activeDownloadId);

      if (activeDownload) {
        activeDownload.classList.add('is-active');
      }
    }
  }

  const downloadButtons = document.querySelectorAll(
    '.download-buttons__button'
  );

  if (downloadButtons) {
    downloadButtons.forEach((button) => {
      button.addEventListener('click', function () {
        toggleDownloads(
          button,
          '.download-buttons__button',
          '.downloads__group'
        );
      });
    });
  }

  const singlePressButtons = document.querySelectorAll(
    '.download .download__press__button'
  );

  if (singlePressButtons) {
    singlePressButtons.forEach((button) => {
      button.addEventListener('click', function () {
        toggleDownloads(
          button,
          '.download .download__press__button',
          '.download .download__press__group'
        );
      });
    });
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
